<template>
  <div class="f2f-schedule">
    <f2f-alert
      :message="getNotification.message"
      :display="getNotification.display"
      @hide="hideNotification"
    />
    <div class="f2f-schedule__container">
      <b-row class="m-0">
        <b-col cols="12">
          <div class="f2f-schedule__head">
            <div class="f2f-schedule__class">
              <div class="schedule-head__title">Classes > Management</div>
              <div class="schedule-head__section">{{ getClass.title }}</div>
            </div>
            <b-row>
              <EnrollComponent
                :classId="$route.params.classId"
                v-if="canEnroll(getClass)"
              />

              <b-button
                id="popover-target"
                class="b-transparent"
                v-if="getClass.cancellation_reason == null"
              >
                <img src="@/assets/wrappers/dots.svg" alt="dots" />
              </b-button>

              <b-popover
                target="popover-target"
                triggers="focus"
                placement="top"
              >
                <div class="edit-option">
                  <div @click="editClass($route.params.classId)">
                    Edit Class
                  </div>
                  <div class="separator"></div>
                  <div @click="cancelClass($route.params.classId)">
                    Cancel Class
                  </div>
                  <div class="separator"></div>
                  <div
                    @click="deleteClass($route.params.classId)"
                    @hide="onDeleteConfirm"
                  >
                    Delete Class
                  </div>
                </div>
              </b-popover>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4 m-0">
        <b-col cols="12">
          <div class="management-list">
            <b-row>
              <b-col class="enrolledUser-text"
                >{{ getClass.registered_students_count }} enrolled users</b-col
              >
            </b-row>
            <b-row class="mt-3">
              <b-col class="start-end-text">{{
                getFormatDuration(getClass)
              }}</b-col>
              <b-col class="row-text">
                {{ getClass && returnEmptyOrName(getClass.facility) }},
                {{ getClass && returnEmptyOrName(getClass.room) }}
              </b-col>
              <b-col class="row-text">
                {{ getClass && returnEmptyOrName(getClass.location) }},
                {{ getClass && returnEmptyOrName(getClass.country) }}
              </b-col>
              <b-col class="row-text">Co-ordinator: - {{ coordinator }}</b-col>
            </b-row>
          </div>
          <div class="management-list mt-4">
            <div class="loader" v-if="getLoadingAllClassSession">
              <b-spinner label="Loading..."></b-spinner>
            </div>

            <div v-if="!getLoadingAllClassSession">
              <div
                v-if="(this.items || []).length == 0"
                class="no-record-holder"
              >
                <div>
                  <img src="@/assets/wrappers/norecord.svg" />
                  <p>No session found!</p>
                </div>
              </div>

              <div v-if="(this.items || []).length > 0">
                <b-table
                  small
                  borderless
                  responsive
                  :items="items"
                  :fields="fields"
                  v-if="!getLoadingAllClassSession"
                >
                  <template #cell(title)="data">
                    <p class="table-text">
                      {{ data.item.title }}
                    </p>
                  </template>
                  <template #cell(presences)="data">
                    <p class="table-text">
                      {{ data.item.presences }}
                    </p>
                  </template>
                  <template #cell(absents)="data">
                    <p class="table-text">
                      {{ data.item.absents }}
                    </p>
                  </template>
                  <template #cell(date_at)="data">
                    <p class="table-text">{{ data.item.date_at }}</p>
                  </template>
                  <template #cell(start_time_at)="data">
                    <p class="table-text">{{ data.item.start_time_at }}</p>
                  </template>

                  <template #cell(options)="data">
                    <b-button
                      :id="'mark-attendance' + data.item.id"
                      class="b-transparent float-right"
                    >
                      <img
                        src="@/assets/wrappers/dots.svg"
                        alt="dots"
                        style="position: relative; bottom: 14px"
                      />
                    </b-button>

                    <b-popover
                      :target="'mark-attendance' + data.item.id"
                      triggers="focus"
                      placement="top"
                    >
                      <div class="edit-option">
                        <div @click="markAttendance(data.item.id)">
                          Mark attendance
                        </div>
                      </div>
                    </b-popover>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-model="editClassModal"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <div class="edit-class">
        <AddOrEditClass :classId="currentClassId" @onEdit="onEdit" />
      </div>
    </b-modal>
    <f2f-modal
      :display="confirmCancel"
      @hide="onCancel"
      type="warning"
      title="Cancel class?"
      body="Are you sure you want to cancel this Class? "
    />
    <b-modal
      id="cancel-modal"
      v-model="unableCancel"
      hide-footer
      hide-header
      centered
    >
      <CancelModal
        :classId="currentClassId"
        @error="onCancelError"
        @hideReason="hideReason"
        @reload="reloadUI"
      />
    </b-modal>

    <f2f-modal
      :display="confirmDelete"
      @hide="onDeleteConfirm"
      type="warning"
      title="Delete class?"
      body="Are you sure you want to delete this Class?"
    />
  </div>
</template>

<script>
import AddOrEditClass from "@/components/classes/schedule/AddOrEditClass.vue";

import EnrollComponent from "@/components/classes/management/EnrollUserComponent.vue";
// import ClassInfo from "@/components/classes/schedule/ClassInfo.vue";
import CancelModal from "@/components/classes/schedule/CancelModal.vue";

import { mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  components: { EnrollComponent, AddOrEditClass, CancelModal },
  data() {
    return {
      editClassModal: false,
      currentClassId: -1,
      confirmCancel: false,
      unableCancel: false,
      confirmDelete: false,
      sessions: [],
      fields: [
        {
          key: "title",
          label: "Session name"
        },
        {
          key: "presences",
          label: "Presences"
        },
        {
          key: "absents",
          label: "Absents"
        },
        {
          key: "date_at",
          label: "Session date"
        },
        {
          key: "start_time_at",
          label: "Session time"
        },
        {
          key: "options",
          label: " "
        }
      ],
      showmodal: false
    };
  },
  computed: {
    ...mapGetters(["getNotification", "getClass", "getLoadingAllClassSession"]),
    coordinator() {
      if (this.getClass && this.getClass.coordinator) {
        return `${this.getClass.coordinator.first_name} ${this.getClass.coordinator.last_name}`;
      } else {
        return "";
      }
    },
    items() {
      return (this.sessions || []).map((s) => {
        const session_date = moment(new Date(s.date_at));
        return {
          date_at: session_date.format("DD/MM/YYYY"),
          start_time_at: `${this.parseAndFormatDate(
            s.start_time_at,
            "HH:mm:ss",
            "HH:mm"
          )} - ${this.parseAndFormatDate(s.end_time_at, "HH:mm:ss", "HH:mm")}`,
          presences: "1",
          absents: "2",
          id: s.id,
          title: s.title
        };
      });
    }
  },
  mounted() {
    this.$store.dispatch("getCourseDetails", this.$route.params.id);
    this.loadClassAndSession();
  },
  methods: {
    getFormatDuration(classObj) {
      if (classObj) {
        return `${moment(new Date(classObj.start_date_at)).format(
          "MMM Do"
        )} to ${moment(new Date(classObj.end_date_at)).format("MMM Do, YYYY")}`;
      } else {
        return "";
      }
    },
    enrollUser() {
      this.showmodal = true;
    },
    hideNotification() {
      this.$store.dispatch("hideNotification");
    },
    async loadClassAndSession() {
      this.$store.dispatch("getClassDetails", {
        courseId: this.$route.params.id,
        classId: this.$route.params.classId
      });
      const sessionsRes = await this.$store.dispatch("getSessionsForClass", {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId
      });
      this.sessions = sessionsRes && sessionsRes.data ? sessionsRes.data : [];
    },

    editClass(classId) {
      this.currentClassId = classId;
      this.editClassModal = true;
    },
    onEdit(edited) {
      this.editClassModal = false;
      edited && this.reloadUI();
    },
    reloadUI() {
      this.$store.dispatch("listAllClasses", {
        course_id: this.$route.params.id,
        isSilentLoad: true
      });
      this.$store.dispatch("listFilteredClasses", {
        course_id: this.$route.params.id,
        status: this.selectedStatus || "future"
      });
    },

    cancelClass(classId) {
      this.currentClassId = classId;
      this.confirmCancel = true;
    },
    async onCancel(userAction) {
      if (userAction) {
        try {
          this.confirmCancel = false;
          this.unableCancel = true;
        } catch {
          this.unableCancel = false;
        }
      }
      this.confirmCancel = false;
    },
    onCancelError() {
      this.isCancelError = true;
    },
    hideReason() {
      this.unableCancel = false;
    },
    deleteClass(classId) {
      this.currentClassId = classId;
      this.confirmDelete = true;
    },
    async onDeleteConfirm(userAction) {
      this.confirmDelete = false;
      if (userAction) {
        try {
          await this.$store.dispatch("deleteClass", {
            courseId: this.$route.params.id,
            classId: this.currentClassId
          });
          this.$store.dispatch("showNotification", {
            message: "Class deleted successfully!"
          });
          this.reloadUI();
        } catch (err) {
          this.isDeleteError = true;
          console.log(err);
        }
      }
    },
    async markAttendance(session_id) {
      this.$router.push({
        name: "attendance",
        params: { classId: this.$route.params.classId, session_id: session_id }
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.no-record-holder {
  height: 60vh;
  min-height: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 80px;
    margin-bottom: 1rem;
  }
}

.table-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #52575c;
}

::v-deep #cancel-modal .modal-body {
  padding: 0px;

  .form-space {
    min-width: auto;
  }
}

.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
}

.row-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #52575c;
}

.start-end-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #25282b;
}

.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }
}

.f2f-schedule {
  .loader {
    margin-top: 20vh;
    text-align: center;
  }

  .f2f-schedule__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;

    .f2f-schedule__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .schedule-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .schedule-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 48px;
        letter-spacing: 0.1px;
        color: #333333;
        word-break: break-word;
      }
      .f2f-schedule__class {
        max-width: 80%;
      }
    }

    .f2f-schedule-info {
      .class-info__head {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #25282b;
        margin-bottom: 6px;
      }

      .class-info__text {
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575c;
      }
    }
  }

  .management-list {
    border: 1px solid #b2b2b2;
    background: white;
    padding: 1rem;
  }

  ::v-deep {
    thead {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #a0a4a8;
    }
  }

  ::v-deep tbody {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .enrolledUser-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 16px;
    /* or 67% */

    letter-spacing: 0.2px;

    color: #1176b2;
  }

  .edit-option {
    background: #ffffff;
    border-radius: 4px;
    border: none;

    .separator {
      border: 0.5px solid #e8e8e8;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 120px;
    }

    div {
      color: #52575c;
      font-size: 16px;
    }

    div:hover {
      color: #0043ac;
      cursor: pointer;
    }
  }

  .b-transparent {
    background: transparent;
    border: none;

    :focus {
      background: transparent;
    }
  }

  .btn-secondary:hover {
    background: none;
  }

  .btn-secondary:focus {
    background: none;
  }
}
</style>
